import React from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout, VersusTable, Link, AffiliateDisclosure } from '../components/common'
import { MetaData } from '../components/common/meta'
import { Button, Pane } from 'evergreen-ui';
import { platformMap } from '../utils/constants';
import { slugToRatesMap } from '../data/ratesTableInfo';

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/

const VersusPage = (props) => {
    const { location, pageContext: { ratesAPath, ratesBPath, canonical_url } } = props;
    const ratesA = slugToRatesMap[ratesAPath];
    const ratesB = slugToRatesMap[ratesBPath];
    let earnText = 'Earn ';
    if (ratesA.key === 'gemini' || ratesB.key === 'gemini') {
        earnText = '';
    }

    const data = {
        ghostPage: {
            title: `${ratesA.platform} vs ${ratesB.platform}: ${earnText}Interest Rates Comparison`,
            description: `Interest rates comparison of ${ratesA.platform} vs ${ratesB.platform}. Compare the APY / APR of each cryptocurrency, and find the best place to earn on your crypto.`,
            canonical_url,
        },
    };

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="website"
            />
            <Layout location={location}>
                <div className="container">
                    <article className="content">
                        {/* <figure className="post-feature-image">
                            <Pane>
                                <img width={100} src={platformMap[ratesA.key].imageUrl} />
                                <b>VS</b>
                                <img width={100} src={platformMap[ratesB.key].imageUrl} />
                            </Pane>
                        </figure> */}
                        <Pane className="versus-page-featured-images">
                            <Pane width="40%">
                                <img src={platformMap[ratesA.key].featuredImage}></img>
                            </Pane>
                            <Pane width="40%">
                                <img src={platformMap[ratesB.key].featuredImage}></img>
                            </Pane>
                        </Pane>
                        <h1 className="content-title">{data.ghostPage.title}</h1>
                        <section className="content-body load-external-scripts post-card-excerpt">
                            <p>Interest rates comparison of {ratesA.platform} vs {ratesB.platform}. Compare the base and max APY / APR of each supported cryptocurrencies, and find the best place to earn on your crypto.</p>
                        </section>
                        <VersusTable ratesA={ratesA} ratesB={ratesB} />
                        {(ratesA.bonusHTML || ratesB.bonusHTML) && <section className="post-full-content  content-body">
                            <h2 id="sign-up-bonuses">Sign Up Bonuses</h2>
                        </section>}
                        {ratesA.bonusHTML && <section className="post-full-content  content-body">
                            <h6>{ratesA.platform}</h6>
                        </section>}
                        <section
                            className="post-full-content content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: ratesA.bonusHTML }}
                        />
                        {ratesB.bonusHTML && <section className="post-full-content  content-body">
                            <h6>{ratesB.platform}</h6>
                        </section>}
                        <section
                            className="post-full-content content-body load-external-scripts"
                            dangerouslySetInnerHTML={{ __html: ratesB.bonusHTML }}
                        />
                        <Pane className="post-full-content" textAlign="center">
                            <hr/>
                            <Link to={platformMap[ratesA.key].versusPath} style={{ textDecoration: 'none' }}>
                                <Button marginRight={12} marginBottom={12} size="large" appearance="default">
                                    More {ratesA.platform} Comparisons
                                </Button>
                            </Link>
                            <Link to={platformMap[ratesB.key].versusPath} style={{ textDecoration: 'none' }}>
                                <Button marginRight={12} marginBottom={12} size="large" appearance="default">
                                    More {ratesB.platform} Comparisons
                                </Button>
                            </Link>
                        </Pane>
                        <AffiliateDisclosure large />
                    </article>
                </div>
            </Layout>
        </>
    )
}

export default VersusPage

// export const postQuery = graphql`
//     query($slug: String!) {
//         ghostPage(slug: { eq: $slug }) {
//             ...GhostPageFields
//         }
//     }
// `
